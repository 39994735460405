<script lang="ts" setup>
const scrollWrapper = ref();
const document = ref();

const route = useRoute();
function scrollToDocument() {
  const rect = document.value.getBoundingClientRect();
  scrollWrapper.value?.scrollTo({ left: rect.left, behavior: "smooth" });
}
onMounted(() => {
  scrollToDocument();
});
watch(() => route.params.id, scrollToDocument);

usePageNavigation(document, "document");
</script>

<template>
  <div
    ref="scrollWrapper"
    :class="
      cn(
        'h-[100dvh] w-full relative flex bg-neutral-1',
        'overflow-x-auto overflow-y-hidden xl:overflow-hidden',
        'max-xl:snap-x max-xl:snap-mandatory',
        'max-xl:py-12'
      )
    "
  >
    <Navigation />
    <section
      id="document"
      ref="document"
      :class="
        cn(
          'max-xl:w-[100vw] max-xl:shrink-0 xl:basis-0 xl:grow h-full flex flex-col',
          'scroll-area max-xl:snap-start',
          'px-5 max-xl:py-5',
          '@container'
        )
      "
    >
      <NuxtPage />
    </section>
  </div>
</template>
